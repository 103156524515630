<template>
    <div>
        <v-row>
            <v-col>
                <v-card flat color="#f1f1f1" style="max-width: 600px">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="title">
                                {{ __('Categorias padrões', 'settings') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card-text class="pt-0">
                        {{ __('As categorias padrões são utilizadas internamente pelo sistema para configurações de geração de arquivos, integração e listagem', 'settings') }}
                    </v-card-text>
                </v-card>

                <v-card flat class="ma-4" style="max-width: 600px">
                    <v-card-title>
                        {{ __('Parâmetros', 'settings') }}
                        <v-spacer />
                        <v-icon @click="getData" left>mdi-refresh</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-alert
                            outlined
                            type="warning"
                            border="left"
                            dense
                            class="mb-0"
                        >
                            {{ __('Todos os parâmetros devem possuir uma categoria e elas devem ser coerentes com o propósito do parâmetro', 'settings') }}
                        </v-alert>
                    </v-card-text>
                    <v-list v-if="!fetching">
                        <div v-for="(parameter, index) in parameters" :key="`category-${index}-${parameters.parameter}`">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <div class="pb-1" v-for="(description, index) in parameter.descriptions" :key="parameter.parameter + index">
                                            - {{ description }}
                                        </div>
                                    </v-list-item-title>
                                    <v-list-item-title class="mt-2 text-wrap">
                                        <v-autocomplete
                                            :label="__('Categoria', 'settings')"
                                            :value="parameter.attachment_category_id"
                                            @change="handleParameterChange(parameter.parameter, $event)"
                                            :items="categories"
                                            item-text="description"
                                            item-value="id"
                                            :return-object="false"
                                        />
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mx-2" v-if="(index + 1) < parameters.length"></v-divider>
                        </div>
                    </v-list>
                    <v-skeleton-loader
                      v-else
                      type="list-item,list-item,list-item,list-item,list-item,list-item"
                    ></v-skeleton-loader>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { indexManagementCategories, indexDefaultCategories, updateDefaultCategory } from '@/services/config/attachment_categories';
import i18n from '@/mixins/i18n';

export default {
    mixins: [i18n],
    data() {
        return {
            fetching: false,
            categories: [],
            parameters: []
        }
    },

    methods: {
        async handleParameterChange(parameterName, attachment_category_id) {
            this.parameters = this.parameters.map(filterParameter => {
                if (filterParameter.parameter === parameterName) {
                    filterParameter.attachment_category_id = attachment_category_id;
                }

                return filterParameter;
            });

            await updateDefaultCategory({
                config_default_attachment_category: {
                    parameter: parameterName,
                    attachment_category_id
                }
            });
        },
        
        async getData() {
            this.fetching = true;
            const response_categories = await indexManagementCategories();
            this.categories = response_categories;

            const response_parameters = await indexDefaultCategories();
            this.parameters = response_parameters;
            this.fetching = false;
        },
    },

    created() {
        this.getData();
    }
}
</script>